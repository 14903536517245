html,
body,
* {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

a {
  color: #0366d6;
  text-decoration: none !important;
}

code {
  color: #e01a76;
}

.container {
  overflow: hidden;
}

body {
  color: black !important;
}

.allAppContainer {
  background: white; /* fallback for old browsers */
}
#logo {
  width: 150px;
}

.title {
  font-weight: bold;
  font-size: 25px;
  text-shadow: 1px 1px 2px rgba(77, 3, 4, 1);
  line-height: 1.4;
  padding: 15px;
}
.tabs-title {
  color: rgb(155, 155, 204);
  font-weight: bold;
  font-size: 30px;
  text-shadow: 0 0 1px rgba(178, 252, 251, 0.7),
    /* Light cyan shadow for a subtle glow */ 0 0 1px rgba(178, 252, 251, 0.5),
    /* Additional cyan shadow for depth */ 0 0 1px rgba(178, 252, 251, 0.3);
  line-height: 1.4;
  padding: 15px;
}

.text {
  font-size: 24px; /* Adjust size as needed */
  text-shadow: 0 0 20px rgba(178, 252, 251, 0.7),
    /* Light cyan shadow for a subtle glow */ 0 0 5px rgba(178, 252, 251, 0.5),
    /* Additional cyan shadow for depth */ 0 0 7px rgba(178, 252, 251, 0.3);
  line-height: 1.4;
  text-align: center;
  padding: 10px;
}

.whatIsProbate {
  position: relative; /* Ensure content appears above the background */
  z-index: 2; /* Set z-index to 2 to position it above the background blur */
}

.whyMightYouNeedIt {
  position: relative; /* Ensure content appears above the background */
  z-index: 2; /* Set z-index to 2 to position it above the background blur */
}
#contactUs {
  font-family: 'Roboto Slab', serif;
  color: white;
}

.card2 {
  background-color: #aca9a6 !important;
}

/* Custom styles for the fancy list group */
.fancy-list-group {
  border-radius: 10px; /* Rounded corners */
}

/* Custom styles for list group items */
.fancy-list-group-item {
  border-color: transparent; /* Remove border for list group items */
}

.icon {
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.6))
    drop-shadow(2px 2px 10px rgba(0, 0, 139, 0.5));
}

/* Getting rid of the arrows from the input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* overlay isLoading */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* //---------------- */
@media only screen and (max-width: 575px) {
  .nav-item {
    border-radius: 5px !important;
    display: flex;
    justify-content: center;
  }
  .menu_buttons {
    background-color: none;
  }

  a {
    text-decoration: none !important;
    /* color: rgb(64, 64, 64) !important; */
    line-height: 25px !important;
    width: 100%;
  }
  #logo {
    width: 100px;
  }
  #coming img {
    width: 150px;
  }
  #contactEmail {
    color: white !important;
    text-decoration: underline !important;
  }
}

@media (max-width: 767.98px) {
  /* Image on top, text on bottom in mobile view */
  .whatIsProbate .row > div {
    width: 100%;
  }
  .title {
    font-size: 24px; /* Reduce font size for smaller screens */
  }
  .tabs-title {
    font-size: 20px; /* Reduce font size for smaller screens */
    padding-left: 0%;
    padding-right: 0%;
  }
  .text {
    font-size: 18px !important; /* Reduce font size for smaller screens */
  }
}

@media (min-width: 1200px) {
  #probate-loans-text {
    font-size: 90px !important;
  }
}

/* roboto font styles */

.roboto-thin {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: italic;
}
